<script>
import { Service } from "../store/service";
// import Header from "../views/Header";

const Api = new Service();
export default {
  components: {
    // Header
  },
  data() {
    return {
      submittingForm: false,
      selectedBedroom: "1",
      remarks: "",
      modalStatus: null,
      describes: [
        "Realtor",
        "Agent",
        "Student",
        "Employee",
        "Civil Servant",
        "Entreprenuer",
        "Building Professionals",
        "Military / Police",
        "Others"
      ],
      userDescription: "",

      name: "",
      phone: "",
      email: "",
      errorMessages: {
        form: "",
        name: "",
        phone: "",
        email: "",
        remarks: "",
        userDescription: ""
      }
    };
  },

  methods: {
    sortLocationAsc(prev, curr) {
      const prevLocationName = prev.name.toLowerCase();
      const currLocationName = curr.name.toLowerCase();

      return prevLocationName < currLocationName ? -1 : 1;
    },

    resetForm() {
      (this.name = ""),
        (this.phone = ""),
        (this.email = ""),
        (this.userDescription = "");
    },
    submitDetails() {
      //reset general form error
      this.errorMessages.form = "";
      const emailTest = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (this.name == "") {
        this.errorMessages.name = "Please input name";
        return;
      } else {
        this.errorMessages.name = "";
      }

      if (this.userDescription == "") {
        this.errorMessages.userDescription = "Please select one option";
        return;
      } else {
        this.errorMessages.userDescription = "";
      }
      if (!Number(this.phone) || this.phone.length < 11) {
        this.errorMessages.phone =
          "Phone number must be a number and up to 11 digits";
        return;
      } else {
        this.errorMessages.phone = "";
      }

      if (!emailTest.test(this.email)) {
        this.errorMessages.email =
          "Please for email correctly [johndoe@example.com]";
        return;
      } else {
        this.errorMessages.email = "";
      }

      let details = {
        email: this.email,
        fullname: this.name,
        phone: this.phone,
        // eslint-disable-next-line @typescript-eslint/camelcase
        what_describe_you: this.userDescription
      };

      details = JSON.stringify(details);
      this.submittingForm = true;

      Api.postRequest("eventapplication", details)
        .then(data => {
          const response = data.data;
          if (response.success) {
            this.resetForm();

            this.modalStatus = null;
            this.submittingForm = false;

            this.modalStatus = "success";
          } else {
            this.errorMessages.form =
              "Unable to submit form: " + response.error;
            this.submittingForm = false;
          }
        })
        .catch(err => {
          console.error(err);
          this.errorMessages.form = "Unable to submit form: ";
        });
    },

    verifyPropertyDetails() {
      if (this.location == "") {
        this.errorMessages.location = "Please select location";
        return;
      } else {
        this.errorMessages.location = "";
      }

      if (this.propertyType == "") {
        this.errorMessages.propertyType = "Please select property type";
        return;
      } else {
        this.errorMessages.propertyType = "";
      }
      if (this.remarks == "") {
        this.errorMessages.remarks = "Please give your remarks";
        return;
      } else {
        this.errorMessages.remarks = "";
      }
      if (this.propertySubType == "") {
        this.errorMessages.propertySubType = "Please select property sub tyoe";
        return;
      } else {
        this.errorMessages.propertySubType = "";
      }

      if (this.minAmount == "" || this.maxAmount == "") {
        this.errorMessages.price = "Minimum price and Maximum price required";

        return;
      } else if (
        Number(this.minAmount) < 200000 ||
        Number(this.maxAmount) > 10000000
      ) {
        this.errorMessages.price =
          "Prices should not be less than 200,000 and not more than 10,000,000";
        return;
      } else {
        this.errorMessages.price = "";
      }

      this.modalStatus = "pending";
    },
    checkLogger() {
      Api.postRequest(`logcounter`, {})
        .then(res => {
          // console.log("Who reads", res);
        })
        .catch(err => {
          console.log(err);
          this.loader = false;
        });
    },
    moveTo: function(id) {
      this.$router.push({
        path: `/property/${id}`
      });
    },
    addProperty() {
      // localStorage.setItem("shelta-loger", "Tenant");
      this.$router.push({
        path: `/rentpayer`
      });
    },
    fetchProperties: async function() {
      this.loader2 = true;
      try {
        await Api.getRequest(`fetchpropertiesrandomly`).then(res => {
          this.properties = res.data.properties;
          this.loader2 = false;
        });
      } finally {
        console.log("Closed connection");
      }
    },

    // For rent payer

    getPropertyTypes: () => {
      Api.getRequest("fetchpropertytypes")
        .then(result => {
          if (result.data.success) {
            this.propertyTypes = result.data.propertytypes;
          } else {
            throw new Error("unable to get property types");
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  watch: {
    propertyType(newVal) {
      if (newVal) {
        const typeId = this.propertyTypes.filter(type => type.type == newVal)[0]
          .id;

        Api.getRequest("fetchpropertysubtypesbytypeid/" + typeId)
          .then(result => {
            if (result.data.success) {
              this.propertySubTypes = result.data.propertysubtypes;
            } else {
              throw new Error("unable to get property subtypes");
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  }
};
</script>
<template>
  <div class="find_property">
    <div class="header">
      <div class="text-center mb-5 user-form-head">
        <div class="mb-2 pt-5">
          <img src="@/assets/logo.svg" alt="shelta logo" />
        </div>
      </div>
    </div>

    <div class="container main-container">
      <div class="page-image  event-details">
        <h1>
          EARN MORE LIKE A PRO WITH REAL ESTATE
        </h1>

        <div>
          <p>
            We understand how busy you are and that you have a little time to
            engage with another income source. And this is where we step in...
          </p>
          <br />
          <p>
            Freshen up your approach to the real estate business at this
            exclusive event made for you.
          </p>

          <ul class="event-feats">
            <li>
              Network with like-minded people and have interesting
              conversations.
            </li>
            <li>Stay connected and share your memories as they happen.</li>
            <li>
              Set your income for success, using exclusive insights from
              seasoned industry leaders.
            </li>
          </ul>
        </div>

        <div class="event-schedule">
          <p>Date: <span>Friday, 2nd June 2023</span></p>
          <p>Time: <span>9am - 11am </span></p>
          <p>Venue: <span>Novare Mall, Wuse Zone 5, FCT-ABUJA</span></p>
        </div>
      </div>
      <div class="d-block  form-container ">
        <!-- MAIN FORM -->

        <div v-if="modalStatus == null">
          <div class="text-center mb-3 user-form-head">
            <h2>
              Sign up for event
            </h2>
          </div>
          <div>
            <form class="text-left" @submit.prevent="submitDetails">
              <p v-if="errorMessages.form" class="input-error">
                {{ errorMessages.form }}
              </p>
              <div class="form-group ">
                <label class="select-label " for="location">Name </label>
                <input
                  type="text"
                  class="form-control form-select"
                  v-model="name"
                />
                <p v-if="errorMessages.name" class="input-error">
                  {{ errorMessages.name }}
                </p>
              </div>
              <div class="form-group ">
                <label class="select-label " for="location"
                  >Email address
                </label>
                <input
                  type="email"
                  class="form-control form-select"
                  v-model="email"
                />
                <p v-if="errorMessages.email" class="input-error">
                  {{ errorMessages.email }}
                </p>
              </div>
              <div class="form-group ">
                <label class="select-label " for="location"
                  >Phone Number
                </label>
                <input
                  type="number"
                  class="form-control form-select"
                  v-model="phone"
                />
                <p v-if="errorMessages.phone" class="input-error">
                  {{ errorMessages.phone }}
                </p>
              </div>

              <div class="form-group email-group">
                <label class="select-label " for="location"
                  >What best describes you?
                </label>
                <select
                  v-model="userDescription"
                  id="location"
                  name="location"
                  class="form-control form-select"
                >
                  <option value="" selected disabled>--select--</option>
                  <option v-for="(describe, index) in describes" :key="index">
                    {{ describe }}
                  </option>
                </select>
                <p v-if="errorMessages.userDescription" class="input-error">
                  {{ errorMessages.userDescription }}
                </p>
              </div>

              <button class="modal-next btn">
                Send my ticket
              </button>
            </form>
          </div>
        </div>

        <!-- FEEDBACK DETAILS FORM -->

        <!-- success modal -->
        <div v-if="modalStatus == 'success'" class="success-container">
          <div>
            <p>
              Congratulations! Kindly check your submitted email for event
              update.🎉
            </p>

            <a
              href="https://smart.myshelta.com/?utm_source=myshelta.com/event&utm_medium=button&utm_campaign=smart"
              class="modal-next btn"
            >
              Learn More About Smart
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;
$yellow: #ffb100;
$listing-bg: #64abb2;
$fontFamily: linernear;
p,
label {
  margin-bottom: 0 !important;
}
.main-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;

  > * {
    /* flex: 1; */
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.event-details {
  /* display: none; */
  order: 2;
  flex: 1.2;

  h1 {
    font-size: clamp(18px, 3vw, 24px);
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;

    @media screen and (min-width: 1024px) {
      text-align: left;
    }
  }

  p {
    font-size: clamp(16px, 4vw, 18px);
  }

  text-align: left;
  @media screen and (min-width: 1024px) {
    order: 0;
  }
}

.event-feats {
  padding-left: 10px;
  margin-bottom: 2.5rem;

  > * + * {
    margin-top: 1rem;
  }

  li {
    padding-left: 8px;
    font-size: clamp(16px, 4vw, 18px);
  }
  li::marker {
    content: url("../assets/images/list_bullet.svg");
  }
}

.event-schedule {
  p {
    font-size: clamp(18px, 4vw, 24px);
    font-weight: 700;
    span {
      color: $primary;
    }
  }
}

.success-container {
  display: grid;
  place-items: center;
  height: 100%;

  p {
    font-weight: 500;
    font-size: 1.3rem;
  }
}

.find_property {
  /* background-color: #fff; */
  min-height: 100vh;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &::after {
    content: url("../assets/images/event_bg.png");
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.3);
    z-index: -1;
  }

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 120%;
}

.modal-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.user-form-head {
  h2 {
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: capitalize;
  }

  p {
    font-size: 14px;
  }
}

.header {
  position: sticky;
  top: 0;

  .nav_holder {
    position: relative;
    margin-bottom: 0;
  }
}

.input-error {
  font-size: 12px;
  color: red;
  text-transform: capitalize;
}

.modal-next {
  background-color: $primary;
  width: 100%;
  color: #ffffff;
  margin: 1rem 0;
  &:hover {
    color: #ffffff;
    opacity: 0.9;
  }
  &[disabled] {
    opacity: 0.5;
  }
}

form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  label {
    text-align: left;
  }
}

.form-container {
  padding: 2rem;
  background-color: #ffffff;
  margin-bottom: 1rem;
  order: 0;
  box-shadow: 5px 5px 21px -20px gray, -5px -5px 21px -20px gray;
  border-radius: 30px;
  flex: 0.8;
}

.property-types-container {
  display: grid;
  gap: 1rem;

  /* @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  } */
}

.form-header {
  font-weight: 700;
  font-size: 2rem;
  text-transform: uppercase;
}

.what-you-want {
  display: flex;
}

.bedroom-select-container {
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
}

.custom-radio {
  border: 1px solid $primary;
  color: $primary;
  font-size: 1rem;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 300;
  text-transform: capitalize;
  cursor: pointer;
  &.active {
    background-color: #0033ea;
    color: #ffffff;

    .icon {
      display: block;
    }
  }
}

.bedroom-radio {
  border: 1px solid $primary;
  color: $primary;
  border-radius: 5px;
  font-size: 1rem;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;

  &.bedroom-active {
    background-color: #0033ea;
    color: #ffffff;
  }
}

.price-range-input-container {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  gap: 1rem;

  .dash {
    font-weight: 700;
    font-size: 2rem;
  }

  input {
    border: 1px solid $primary;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  display: block;
  width: 100%;
  min-height: 10rem;
  padding: 1rem;
}
</style>
